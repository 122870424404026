import React, { useContext } from "react";
import {
  StyledHiveTechCrowdHeader,
  StyledHiveTechCrowdHeaderTitle,
  StyledHiveTechCrowdHeaderSubtitle,
  StyledHiveTechCrowdHeaderParagraph,
} from "./style";
import { Button } from "@3beehivetech/components";
import { DataContext } from "../../contexts/DataContext";
import Link from "../Link/Link";

export default ({
  title,
  subtitle,
  soldDevices,
  buyers,
  buyButton,
  contactButton,
  contactLink,
}) => {
  const { labels } = useContext(DataContext);
  const scrollToRef = (id, smooth = true, yOffset = 0) => {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  };

  /*
  const [oldSoldDevices, oldBuyers] = useMemo(() => {
    const t0 = Date.parse('2021-02-02T15:00:00')
    const now = Date.now()
    const hours = (now-t0)/1000/60/60
    if (hours < 0) {
      return [0, 0]
    }
    const devices = hours * 5
    const buyers = hours * 2
    return [
      devices > 201 ? 201 : Math.trunc(devices),
      buyers > 88 ? 88 : Math.trunc(buyers),
    ]
  }, [])

  const soldDevicesCounter = useCounter(soldDevices + oldSoldDevices, 500)
  const buyersCounter = useCounter(buyers + oldBuyers, 500)
  */
  return (
    <StyledHiveTechCrowdHeader>
      <StyledHiveTechCrowdHeaderTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledHiveTechCrowdHeaderSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />

      <StyledHiveTechCrowdHeaderParagraph>
        <span>{soldDevices}</span> {labels.DEVICES_SOLD}
      </StyledHiveTechCrowdHeaderParagraph>
      <StyledHiveTechCrowdHeaderParagraph>
        <span>{buyers}</span> {labels.BUYERS}
      </StyledHiveTechCrowdHeaderParagraph>
      <div style="display:flex; margin-top: 20px; flex-wrap: wrap; row-gap: 20px;">
        <Button style="bottom: 30px; height: 40px;" onClick={() => scrollToRef("perks", true, 64)}>
          {buyButton}
        </Button>
        {contactButton && contactLink && (
          <Link to={contactLink}>
            <Button style="margin-left: 20px; bottom: 30px; height: 40px;">
              {contactButton}
            </Button>
          </Link>
        )}
      </div>
    </StyledHiveTechCrowdHeader>
  );
};
